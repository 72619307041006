<template>
    <front-layout>
        <v-container fluid style="min-height: calc(100vh - 228px)">
            <v-container v-if="blogs.length > 0">
                <v-row>

                    <v-col cols="12">
                        <h1 class="blog__h1">{{ $t('blog.blog') }}</h1>
                    </v-col>

                    <v-col xs="10" sm="10" md="9" lg="9" xl="9" cols="10" class="pa-0"
                        v-if="$vuetify.breakpoint.width > 1072">

                        <blog-card v-for="blog in blogs" :blog="blog" :key="blog.id"></blog-card>

                        <v-row class="blog__bottom">
                            <v-col class="blog__bottom-num">
                                <div @click="prevPage()" class="blog__bottom-num-left">
                                    <p>←</p>
                                </div>
                                <p style="cursor: pointer;" @click="switchPage(current_page - 1)"
                                    v-if="current_page >= 2">{{current_page - 1}}</p>
                                <div v-for="n in max_pages.slice(current_page - 1, current_page + 4)"
                                    :key="n.pageNumber" class="blog__bottom-num-block">
                                    <p v-if="n.pageNumber == current_page"
                                        style="background-color: #3F44C3; color: #fff;">{{ n.pageNumber }}</p>
                                    <p @click="switchPage(n.pageNumber)" v-else>{{ n.pageNumber }}</p>
                                </div>
                                <div @click="nextPage()" class="blog__bottom-num-right">
                                    <p>→</p>
                                </div>
                            </v-col>
                        </v-row>

                    </v-col>

                    <v-col xs="12" sm="12" md="12" lg="12" xl="12" cols="12" class="pa-0" v-else>

                        <blog-card v-for="blog in blogs" :blog="blog" :key="blog.id"></blog-card>

                        <v-row class="blog__bottom blog__bottom-column">
                            <v-row class="blog__bottom-num">
                                <div @click="prevPage()" class="blog__bottom-num-left">
                                    <p>←</p>
                                </div>
                                <p style="cursor: pointer;" @click="switchPage(current_page - 1)"
                                    v-if="current_page >= 2">{{current_page - 1}}</p>
                                <div v-for="n in max_pages.slice(current_page - 1, current_page + 4)"
                                    :key="n.pageNumber" class="blog__bottom-num-block">
                                    <p v-if="n.pageNumber == current_page"
                                        style="background-color: #3F44C3; color: #fff;">{{ n.pageNumber }}</p>
                                    <p @click="switchPage(n.pageNumber)" v-else>{{ n.pageNumber }}</p>
                                </div>
                                <div @click="nextPage()" class="blog__bottom-num-right">
                                    <p>→</p>
                                </div>
                            </v-row>
                        </v-row>

                    </v-col>

                    <v-col md="3" class="pt-0">

                        <advertising :limit="4" />

                    </v-col>

                </v-row>
            </v-container>
        </v-container>
    </front-layout>
</template>

<script>
import FrontLayout from "../../../layouts/FrontLayout";
import Blog from "@/models/Blog";
import BlogCard from "../../../components/cards/BlogCard.vue";

export default {
    name: 'blog.index',
    metaInfo() {
        return {
            title: this.$t('meta.blogs_title'),
            meta: [
                {name: 'description', content: this.$t('meta.blogs_description')}
            ]
        }
    },
    components: {
        FrontLayout,
        Advertising: () => import("@/components/ads/Advertising"),
        BlogCard
    },
    data: function () {
        return {
            blogs: [],
            current_page: 1,
            next_page_url: '',
            prev_page_url: '',
            max_pages: [],
            months_ru: ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'],
            months_en: ['January', 'February', 'March', 'April', 'May', 'June', 'Juny', 'August', 'September', 'October', 'November', 'December'],
            months_hy: ['հունվար', 'փետրվար', 'մարտ', 'ապրիլ', 'մայիս', 'հունիս', 'հուլիս', 'օգոստոս', 'սեպտեմբեր', 'հոկտեմբեր', 'նոյեմբեր', 'դեկտեմբեր'],
            locales: ['en', 'hy', 'ru'],
            language: 'en',
        }
    },
    methods: {
        async init() {
            const url = new URL(window.location.href)

            let params = url.searchParams
            let hostname = url.hostname;
            let urlArray = hostname.split('.')

            if (this.locales.includes(urlArray[0])) {
                this.language = urlArray[0]
            } else {
                this.language = 'hy'
            }

            this.isLoading = true

            let page = params.get('page');

            if (page !== null && page > 0) {
                let { data, current_page, next_page_url, prev_page_url, per_page, total } = await Blog.custom('/blog?page=' + page).get()

                let max_pages = Math.ceil(total / per_page)

                for (let i = 1; i <= max_pages; i++) {
                    this.max_pages.push({ pageNumber: i })
                }

                this.blogs = data
                this.current_page = current_page
                this.next_page_url = next_page_url
                this.prev_page_url = prev_page_url

                this.isLoading = false
            } else {
                let { data, current_page, next_page_url, prev_page_url, per_page, total } = await Blog.custom('/blog?page=' + 1).get()

                this.$router.push({ query: { page: current_page } })

                let max_pages = Math.ceil(total / per_page)

                for (let i = 1; i <= max_pages; i++) {
                    this.max_pages.push({ pageNumber: i })
                }

                this.blogs = data
                this.current_page = current_page
                this.next_page_url = next_page_url
                this.prev_page_url = prev_page_url

                this.isLoading = false
            }
        },
        chunkArray(arr, qty) {
            return arr.reduce((prev, cur, i, a) => !(i % qty) ? prev.concat([a.slice(i, i + qty)]) : prev, []);
        },
        nextPage: async function () {
            if (this.max_pages.length >= this.current_page + 1) {
                let next = this.current_page + 1

                this.$router.push({ query: { page: next } })

                let { data, current_page, next_page_url, prev_page_url } = await Blog.custom('/blog?page=' + next).get();

                this.blogs = data

                this.current_page = current_page
                this.next_page_url = next_page_url
                this.prev_page_url = prev_page_url
            }
        },
        prevPage: async function () {
            if (this.current_page > 1) {
                let prev = this.current_page - 1

                this.$router.push({ query: { page: prev } })

                let { data, current_page, next_page_url, prev_page_url } = await Blog.custom('/blog?page=' + prev).get();

                this.blogs = data
                this.current_page = current_page
                this.next_page_url = next_page_url
                this.prev_page_url = prev_page_url
            }
        },
        switchPage: async function (page) {
            if (page <= this.max_pages.length && page > 0) {
                this.$router.push({ query: { page: page } })

                let { data, current_page, next_page_url, prev_page_url } = await Blog.custom('/blog?page=' + page).get();

                this.blogs = data
                this.current_page = current_page
                this.next_page_url = next_page_url
                this.prev_page_url = prev_page_url
            }
        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped lang="scss">
.blog__h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 20px;
}

.blog__card {
    background: #fff;
    min-width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    padding-top: 0px;
    padding-bottom: 0px;

    .blog__card__footer {
        align-items: end;
    }

    .blog__card__footer-bottom {
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        margin-left: 12px;

        a {
            margin-right: 24px;
            color: #3F44C3;
            font-size: 12px;
            font-weight: 500;
        }

        p {
            margin-right: 18px;
            color: #3F44C3;
            position: relative;
            font-size: 12px;
            font-weight: 500;
        }

        p::before {
            content: url('../../../../../public/img/bubble-chat\ 1.png');
            position: absolute;
            top: 0;
            right: 10px;
        }
    }
}

.blog__card-date {
    margin-right: 18px;
    color: #3F44C3;
    position: relative;
    font-size: 12px;
    font-weight: 500;

}

.blog__card-title {
    color: #000;
    font-size: 24px;
}

.blog__card-img {
    width: 285px;
    height: 155px;
    margin-bottom: 15px;
}

.blog__card-text {
    color: #000;
    font-size: 16px;
    display: inline-block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 3.6em;
    line-height: 1.8em;
    margin-bottom: 0;
}

.blog__bottom {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    text-align: left;

    .blog__bottom-num {
        display: flex;
        flex-flow: row;
        align-items: center;
        text-align: center;

        p {
            width: 38px;
            height: 38px;
            font-size: 24px;
            font-weight: 500;
            margin-right: 15px;
            background-color: #fff;
            color: #3F44C3;
        }

        div {
            cursor: pointer;
        }
    }

    .blog__bottom-show {
        text-align: right;

        p {
            color: #3F44C3;
            font-size: 16px;
        }
    }

    .column {
        display: flex;
        flex-flow: column;
    }
}


.blog__adv {
    background-color: #fff;
    width: 295px;

    .blog__adv-content {
        padding: 21px;

        .blog__adv-content-bottom {
            color: #3F44C3;
            font-size: 16px;
            font-weight: 700;
            margin: 0;
            padding: 0;
        }

        .blog__adv-content {
            h1 {
                color: #000;
                font-weight: 500;
                font-size: 24px;
            }

        }
    }
}

.blog__bottom-column {
    display: flex;
    flex-flow: column;
    text-align: center;
    align-items: center;
    justify-content: space-between;

    .blog__bottom-show {
        p {
            margin-bottom: 42px;
            margin-top: 32px;
        }
    }
}

.blog__adv-container {
    padding-top: 0;
    margin-left: 8px;
}
</style>
