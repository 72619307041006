<template>
    <div class="blog__card" v-if="$vuetify.breakpoint.width > 1072">
        <router-link style="text-decoration: none" :to="{name: 'blogs.show', params: {blog: blog.id}}">
            <v-row>
                <v-col cols="4" style="padding-bottom: 0px;">
                    <v-img class="blog__card-img" :src="`${blog.main_img_url}`">
                    </v-img>
                </v-col>
                <v-col cols="8"
                    style="display: flex; flex-flow: column; justify-content: space-between; padding-bottom: 0px;">
                    <p class="blog__card-date">
                        {{ new Date(blog.created_at).getDate() }}
                        {{ language == 'ru' ? months_ru[new Date(blog.created_at).getMonth()] : ''
                        }}
                        {{ language == 'en' ? months_en[new Date(blog.created_at).getMonth()] : ''
                        }}
                        {{ language == 'hy' ? months_hy[new Date(blog.created_at).getMonth()] : ''
                        }}
                    </p>

                    <h2 class="blog__card-title">
                        {{ language == 'ru' ? blog.title_ru : '' }}
                        {{ language == 'en' ? blog.title_en : '' }}
                        {{ language == 'hy' ? blog.title_hy : '' }}
                    </h2>

                    <p class="blog__card-text">
                        {{ language == 'ru' ? blog.text_ru : '' }}
                        {{ language == 'en' ? blog.text_en : '' }}
                        {{ language == 'hy' ? blog.text_hy : '' }}
                    </p>
                    <v-row class="blog__card__footer">
                        <v-col col="12" class="blog__card__footer-bottom">
                            <div>
                                <a v-for="tag in tags" :key="tag.id">
                                    {{ language == 'ru' ? tag.text_ru : '' }}
                                    {{ language == 'en' ? tag.text_en : '' }}
                                    {{ language == 'hy' ? tag.text_hy : '' }}
                                </a>
                            </div>
                            <p>{{ this.blog.comments.length }}</p>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </router-link>
    </div>
    <div class="blog__card" v-else>
        <router-link style="text-decoration: none" :to="{name: 'blogs.show', params: {blog: blog.id}}">
            <v-row>
                <v-col cols="12" style="display: flex; flex-flow: column; justify-content: space-between;">
                    <v-img :src="`${blog.main_img_url}`" :height="$vuetify.breakpoint.width > 900 ? 400 : 200"
                        alt="City photo">
                    </v-img>
                    <p class="blog__card-date mt-2">
                        {{ new Date(blog.created_at).getDate() }}

                        {{ language == 'ru' ? months_ru[new Date(blog.created_at).getMonth()] : ''
                        }}
                        {{ language == 'en' ? months_en[new Date(blog.created_at).getMonth()] : ''
                        }}
                        {{ language == 'en' ? months_hy[new Date(blog.created_at).getMonth()] : ''
                        }}

                    </p>
                    <h2 class="blog__card-title">
                        {{ language == 'ru' ? blog.title_ru : '' }}
                        {{ language == 'en' ? blog.title_en : '' }}
                        {{ language == 'hy' ? blog.title_hy : '' }}
                    </h2>

                    <p class="blog__card-text">
                        {{ language == 'ru' ? blog.text_ru : '' }}
                        {{ language == 'en' ? blog.text_en : '' }}
                        {{ language == 'hy' ? blog.title_hy : '' }}
                    </p>

                    <v-row class="blog__card__footer">
                        <v-col col="12" class="blog__card__footer-bottom">
                            <div class="">
                                <a v-for="tag in tags" :key="tag.id">
                                    {{ language == 'ru' ? tag.text_ru : '' }}
                                    {{ language == 'en' ? tag.text_en : '' }}
                                    {{ language == 'hy' ? tag.text_hy : '' }}
                                </a>
                            </div>
                            <p>0</p>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </router-link>
    </div>
</template>

<script>
import BlogTag from "@/models/BlogTag";

export default {
    name: 'blogCard',
    props: ['blog'],
    data: function () {
        return {
            tags: [],
            months_ru: ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'],
            months_en: ['January', 'February', 'March', 'April', 'May', 'June', 'Juny', 'August', 'September', 'October', 'November', 'December'],
            months_hy: ['հունվար', 'փետրվար', 'մարտ', 'ապրիլ', 'մայիս', 'հունիս', 'հուլիս', 'օգոստոս', 'սեպտեմբեր', 'հոկտեմբեր', 'նոյեմբեր', 'դեկտեմբեր'],
            locales: ['en', 'hy', 'ru'],
            language: 'en',
        }
    },
    methods: {
        async init() {
            const url = new URL(window.location.href)
            let hostname = url.hostname;
            let urlArray = hostname.split('.')

            if (this.locales.includes(urlArray[0])) {
                this.language = urlArray[0]
            } else {
                this.language = 'hy'
            }

            this.tags = await BlogTag.custom('/blogTags/id/' + this.blog.id).get()
        },
    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped lang="scss">
.blog__card {
    background: #fff;
    min-width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    padding-top: 0px;
    padding-bottom: 0px;

    .blog__card__footer {
        align-items: end;
    }

    .blog__card__footer-bottom {
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        margin-left: 12px;

        a {
            margin-right: 24px;
            color: #3F44C3;
            font-size: 12px;
            font-weight: 500;
        }

        p {
            margin-right: 18px;
            color: #3F44C3;
            position: relative;
            font-size: 12px;
            font-weight: 500;
        }

        p::before {
            content: url('../../../../public/img/bubble-chat\ 1.png');
            position: absolute;
            top: 0;
            right: 10px;
        }
    }
}

.blog__card-date {
    margin-right: 18px;
    color: #3F44C3;
    position: relative;
    font-size: 12px;
    font-weight: 500;

}

.blog__card-title {
    color: #000;
    font-size: 24px;
}

.blog__card-img {
    width: 285px;
    height: 155px;
    margin-bottom: 15px;
}

.blog__card-text {
    color: #000;
    font-size: 16px;
    display: inline-block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 3.6em;
    line-height: 1.8em;
    margin-bottom: 0;
}
</style>
